body {
  margin: 0;
  padding: 0;
}
.row {
	margin-left: 0;
	margin-right: 0;
}
.clear {
    clear: both;   
}
h1 {
    font-size: 34px; 
	font-weight: 700;
	margin-top: 0px;
	margin-bottom: 20px;
	color: #4c9353;
	font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
		small {
			font-size: 75%;
			font-weight: 1000;
			color: #989898;
		} 
		@media (min-width: 1px) and (max-width: 320px) {font-size: 28px; } 
}
h2 {
    color: #4c9353;
	margin-top: 0px;
	font-weight: 800;
	font-size: 26px;
	line-height: 30px;
	font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
		small {
			color: #000;
			font-size: 75%;
			font-weight: 800;
		}
		@media (min-width: 1px) and (max-width: 320px) {font-size: 22px; } 
}
h3 {
    color: #4c9353;
    font-size: 18px;
    font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
}

h3.white{
	color: #fff;
	font-size: 28px;
	line-height: 32px;
	font-style: italic;
	margin-top: 12px;
		@media (min-width: 1px) and (max-width: 320px) {font-size: 24px;}
		@media (min-width: 768px) and (max-width: 991px) {margin-top: 0px;}
		@media (min-width: 992px) and (max-width: 1199px) {margin-top: 0px;}
}

h4 {
    color: #000; 
    font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
}
p {
	font-size: 16px;
	margin-bottom: 26px;
	line-height: 26px;
	font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
		a:link {
			color: #fecd0a;
			text-decoration: none;
			font-weight: 600;
			transition-duration: .4s;
		}
		a:hover {
			color: #4c9353;
			text-decoration: none;
		}
		a:active {
			color: #fecd0a;
			text-decoration: none;
		}
		a:visited {
			color: #fecd0a;
			text-decoration: none;
		}
}
ul {
	margin-bottom: 0;
		.no-pad {
			@media (min-width: 1px) and (max-width: 320px) {padding-left: 0;}
		}
	li {
	    font-size: 16px;
	    line-height: 24px;
	    font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
		    a:link {
			    color: #8e3a97;
			    text-decoration: none;
			    font-weight: 600;
				transition-duration: .4s;
		    }
		    a:hover {
			    color: #4c9353;
			    text-decoration: none;
		    }
		    a:active {
			    color: #8e3a97;
			    text-decoration: none;
		    }
		    a:visited {
			    color: #8e3a97;
			    text-decoration: none;
		    }   
	}
}

ul {
	@media (min-width: 1px) and (max-width: 320px) {padding-left: 0;}
}

.custom-bullet li {
	display: block;
	font-size: 18px;
	line-height: 30px;
}
.custom-bullet li:before	{
	content: "\e006";
	font-family: 'Glyphicons Halflings';
	font-size: 12px;
	float: left;
	margin-top: 0px;
	margin-left: -17px;
	margin-right: 8px;
	color: #7f6d64;
}

.footer {
    color: #fff;
    font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
    a:link {
			color: #fff;
			text-decoration: none;
			font-weight: 600;
			transition-duration: .4s;
		}
		a:hover {
			color: #000;
			text-decoration: none;
		}
		a:active {
			color: #fff;
			text-decoration: none;
		}
		a:visited {
			color: #fff;
			text-decoration: none;
		}   
}
hr {
	border-top: 5px solid #fecd0a;
	margin: 0;
	padding: 0;
}

hr.fancy {
	height: 10px;
	border: 0;
	box-shadow: 0 10px 10px -10px #fecd0a inset;
	margin-top: 15px;
	padding-bottom: 15px;
}

.contact {
    padding: 10px;
    background: #4c9353;
    .phone {
        font-size: 22px; 
	    font-weight: 800;
	    padding-top: 15px;
	    color: #fff; 
	    font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
			a:link {color: #fff}
    }
	.social {
		img {
			@media (min-width: 1px) and (max-width: 320px) {max-width: 48px;}
		}
	}
}
menu {
    padding-top: 20px;
	padding-bottom: 20px;  
	padding-left: 0px;
}
.navbar-default{
	background: #fff;
	border: 0px;
	margin: auto;
		.navbar-nav li a{
    		color: #000;
			font-size: 14px;
			font-weight: 500;
			font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
			margin-right: 0px;
			letter-spacing: .25px;
			transition-duration: .4s;
			line-height: 24px;
				@media (min-width: 992px) and (max-width: 1199px) {font-size: 12px; margin-right: 0px;}
		}			
}
	
/*.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #555555;
    background-color: red;
}*/	
.navbar-default .navbar-nav > .active > a:hover{
	color: #fecd0a;
	background: none;
	}	
.navbar-default .navbar-nav .active a {
	color: #fecd0a;
	background: none;
	}
.navbar-default .navbar-nav li a:hover{
    color: #fecd0a;
}	
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: #fff;
	color: #000;
}
.navbar-default .navbar-collapse{
	border: 0px ;
	box-shadow: none;
	}
.navbar-brand {
	@media (min-width: 992px) and (max-width: 1199px) {padding: 15px 10px 15px 5px;}
	img{
		@media (min-width: 1px) and (max-width: 320px) {max-width: 80%;}
		@media (min-width: 992px) and (max-width: 1199px) {max-width: 200px;}
	}
}
.navbar-brand-centered {
        position: absolute;
        left: 50%;
        margin-top: -30px;
		margin-left: -184px !important;
        display: block;
        /*width: 100%;*/
        text-align: center;
        background-color: transparent;
			@media (max-width: 768px) {left: auto; margin-top: -25px; margin-left: 0px !important;}
			@media (min-width: 768px) and (max-width: 991px) {left: auto; margin-top: -28px; margin-left: 0px !important;}
			@media (min-width: 992px) and (max-width: 1199px) {font-size: 17px; margin-right: 5px; margin-left: -154px !important;}
 }
.navbar-collapse.collapse.in {
	margin-top: 50px;
}
.carousel-caption {
	background: #000;
	padding: 15px 10px 30px;
	bottom: 0;
	left: auto;
	right: auto;
	position: relative;
		@media (min-width: 768px) and (max-width: 991px) {
			background: rgba(0, 0, 0, 0.65);
	        bottom: 18%;
	        left: 20%;
			right: 20%;
	        position: absolute;
			padding: 10px;
		}
		@media (min-width: 992px) and (max-width: 1199px) {
			background: rgba(0, 0, 0, 0.65);
	        bottom: 25%;
	        left: 20%;
			right: 20%;
	        position: absolute;
			padding: 10px;
		}
		@media (min-width: 1200px) {
			background: rgba(0, 0, 0, 0.65);
	        bottom: 25%;
	        left: 20%;
			right: 20%;
	        position: absolute;
			padding: 10px;
		}

	h3 {
		font-size: 18px;
		margin-top: 8px;
		letter-spacing: .25px;
	    position: relative;
	    color: #fff;
		font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
			@media (min-width: 768px) and (max-width: 991px) {
				font-size: 19px;
				letter-spacing: -.5px;
			}
			@media (min-width: 992px) and (max-width: 1199px) {
				font-size: 26px;
				letter-spacing: -.5px;
			}
			@media (min-width: 1200px) {
				font-size: 30px;
			}
	}
	p {
	    font-size: 22px;
	    margin-bottom: 22px;
	    line-height: 24px; 
		font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
		letter-spacing: .5px;
		color: #fecd0a;
			@media (min-width: 768px) and (max-width: 991px) {font-size: 17px;}
	}
}

.carousel-indicators {
	bottom: 15px;
		@media (min-width: 768px) and (max-width: 991px) {bottom: 0px;}
		@media (min-width: 992px) and (max-width: 1199px) {bottom: 0px;}
}
.carousel-indicators li {
    width: 10px;
    height: 10px;
    margin: 1px;
    border: 0px solid #fff;
    border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.55);
		@media (min-width: 768px) and (max-width: 991px) {width: 15px; height: 15px;}
		@media (min-width: 992px) and (max-width: 1199px) {width: 15px; height: 15px;}
		@media (min-width: 1200px) {width: 20px; height: 20px;}
}
.carousel-indicators .active {
    margin: 0;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 1.0);
		@media (min-width: 768px) and (max-width: 991px) {width: 15px; height: 15px;}
		@media (min-width: 992px) and (max-width: 1199px) {width: 15px; height: 15px;}
		@media (min-width: 1200px) {width: 20px; height: 20px;}
}

.button {
    padding: 30px 5px;
    background:#4c9353; 
}
.btn.btn-success.btn-block {
    color: #000;
    background-color: #ffcc00;
    background-image: linear-gradient(to bottom, #ffe270, #ffcc00);
    border-color: #ffcc00 #ffcc00 #ffcc00;
	padding: 16px;
}
.btn.btn-success.btn-block:hover {
    color: #ffffff;
    background-color: #ffcc00;
    background-image: linear-gradient(to bottom, #ffcc00, #ffcc00);
    border-color: #ffcc00 #ffcc00 #ffcc00;
}
main {
  background: url(../images/featured-bg.jpg) no-repeat, linear-gradient( #fff, #fff, #fff);
}
/********************************/
/*         Marketing            */
/********************************/
.marketing {
  padding: 50px 2% 30px 2%;
}
.marketing img {
  width: 100%;
}
/* item styles */
.item-image {
  position: relative;
  overflow: hidden;
  padding-bottom: 50%;
}
.item-image img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.item-content {
  padding: 15px;
  background: transparent;
}
.item-text {
  font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  	@media (min-width: 768px) and (max-width: 991px) {font-size: 16px;}
	@media (min-width: 992px) and (max-width: 1199px) {font-size: 16px;}
}
/********************************/
/*      End Marketing            */
/********************************/

.services {
    margin-top: 20px;  
		@media (max-width: 991px) {margin-top: 0px; } 
}
.main-content {
    padding: 50px 0 40px 0; 
	background: url(../images/asphalt-bg.jpg) no-repeat, linear-gradient(#333333, #333333, #333333);
	background-size: 100%;
		@media (max-width: 991px) {background-size: auto 50%;}
	ul li {
		color: #fff;	
  }
}

.transparency {
	background-color: rgba(0, 0, 0, 0.5);
	padding: 30px;
	overflow: auto;
		p {color: #e6e6e6;}
}

.drawer {
	@media (min-width: 1px) and (max-width: 320px) {padding-left: 0; padding-right: 0;}
}

.panel-heading {
	a:link {color: #8e3a97; transition-duration: .4s;}
	a:hover {color: #4c9353; text-decoration: none;}
}

.panel-title {
	font-family: Futura, 'Trebuchet MS', Arial, sans-serif;
	font-size: 20px;
}	

.panel-body {
	p {color: #333333;}
	h3 {color: #000;}
}

.well {
	overflow: auto;
	p {color: #333333;}
	h3 {font-size: 24px; color: #000; margin-top: 0;}
}

.thumbnail {
	.caption {
		p {color: #333333; margin-bottom: 0;}
		h3 {
			font-size: 22px; color: #000; margin-top: 0;
			@media (min-width: 992px) and (max-width: 1199px) {font-size: 18px; letter-spacing: -.75px;}
		}
	}
}

.tn {
	border: solid 3px #e6e6e6;
	margin-bottom: 10px;
	margin-right: 10px;
	width:100px;
		@media (min-width: 768px) and (max-width: 991px) {width:150px}
		@media (min-width: 992px) and (max-width: 1199px) {width:157px}
		@media (min-width: 1200px) {width:auto;}
}

.bottom-contact {
    padding: 40px 2% 30px 2%;
    background-color: #b18c2e;
    color: #fff;
}
.footer {
    padding: 40px 2% 30px 2%;
    background:#4c9353;
    .copy {
        margin-top: 25px;   
    }
    .auto-logo {
        margin-bottom: 15px;   
    }
}


	
@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    /* since 3.1.0 */
    .navbar-collapse.collapse.in { 
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }
}

//Effects
.grow img{
	transition: 1s ease;
}
.grow img:hover{
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
	transition: 1s ease; 
} 